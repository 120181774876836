import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "challenges"

class Challenge{
    constructor(id, session, category, name, start, end, noteType, period, fileType, noteMax, subventionMax, correctionGrid, correction, fileRef, isVisible, responses, correctionIsVisible){
        this.id = id
        this.session = session
        this.category = category
        this.name = name
        this.start = start
        this.end = end
        this.noteType = noteType
        this.period = period
        this.fileType = fileType
        this.noteMax = noteMax
        this.subventionMax = subventionMax
        this.correctionGrid = correctionGrid
        this.correction = correction
        this.fileRef = fileRef
        this.isVisible = isVisible
        this.responses = responses
        this.correctionIsVisible = correctionIsVisible
    }

    static async initializeOne(sessionId, category, name, start, end){
        let tmp_challenge = new Challenge(null, sessionId, category, name, start, end, "none", null, [], null, null, [], {}, null, false, {}, false)
        return tmp_challenge
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let challenges = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_challenge = new Challenge(doument.id, data.session, data.category, data.name, data.start, data.end, data.noteType, data.period, data.fileType, data.noteMax, data.subventionMax, data.correctionGrid, data.correction, data.fileRef, data.isVisible, data.responses, data.correctionIsVisible)
            challenges.push(tmp_challenge)
        })

        return challenges
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let challenge = new Challenge(response.id, data.session, data.category, data.name, data.start, data.end, data.noteType, data.period, data.fileType, data.noteMax, data.subventionMax, data.correctionGrid, data.correction, data.fileRef, data.isVisible, data.responses, data.correctionIsVisible)

        return challenge
    }

    static async getBySession(sessionId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("session", "==", sessionId))
        let response = await getDocs(documentQuery)

        let challenges = []

        response.forEach(document => {
            let data = document.data()
            let tmp_challenge = new Challenge(document.id, data.session, data.category, data.name, data.start, data.end, data.noteType, data.period, data.fileType, data.noteMax, data.subventionMax, data.correctionGrid, data.correction, data.fileRef, data.isVisible, data.responses, data.correctionIsVisible)
            challenges.push(tmp_challenge)
        })

        return challenges
    }

    static async getByPeriod(periodId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId))
        let response = await getDocs(documentQuery)

        let challenges = []

        response.forEach(document => {
            let data = document.data()
            let tmp_challenge = new Challenge(document.id, data.session, data.category, data.name, data.start, data.end, data.noteType, data.period, data.fileType, data.noteMax, data.subventionMax, data.correctionGrid, data.correction, data.fileRef, data.isVisible, data.responses, data.correctionIsVisible)
            challenges.push(tmp_challenge)
        })

        return challenges
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let challenges = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_challenge = new Challenge(document.id, data.session, data.category, data.name, data.start, data.end, data.noteType, data.period, data.fileType, data.noteMax, data.subventionMax, data.correctionGrid, data.correction, data.fileRef, data.isVisible, data.responses, data.correctionIsVisible)
                challenges.push(tmp_challenge)
            })
            
            if(callback != null){
                callback(challenges)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let challenge = new Challenge(snapshot.id, data.session, data.category, data.name, data.start, data.end, data.noteType, data.period, data.fileType, data.noteMax, data.subventionMax, data.correctionGrid, data.correction, data.fileRef, data.isVisible, data.responses, data.correctionIsVisible)
            
            if(callback != null){
                callback(challenge)
            }
        })
        return unsub
    }

    static async listenBySession(sessionId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("session", "==", sessionId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let challenges = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_challenge =  new Challenge(document.id, data.session, data.category, data.name, data.start, data.end, data.noteType, data.period, data.fileType, data.noteMax, data.subventionMax, data.correctionGrid, data.correction, data.fileRef, data.isVisible, data.responses, data.correctionIsVisible)
                challenges.push(tmp_challenge)
            })
            
            if(callback != null){
                callback(challenges)
            }
        })
        return unsub
    } 

    static async listenByPeriod(periodId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("period", "==", periodId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let challenges = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_challenge =  new Challenge(document.id, data.session, data.category, data.name, data.start, data.end, data.noteType, data.period, data.fileType, data.noteMax, data.subventionMax, data.correctionGrid, data.correction, data.fileRef, data.isVisible, data.responses, data.correctionIsVisible)
                challenges.push(tmp_challenge)
            })
            
            if(callback != null){
                callback(challenges)
            }
        })
        return unsub
    } 

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                session : this.session,
                category : this.category,
                name : this.name,
                start : this.start,
                end : this.end,
                noteType : this.noteType,
                period : this.period,
                fileType : this.fileType,
                noteMax : this.noteMax,
                subventionMax : this.subventionMax,
                correctionGrid : this.correctionGrid,
                correction : this.correction,
                fileRef : this.fileRef,
                isVisible : this.isVisible,
                responses : this.responses,
                correctionIsVisible : this.correctionIsVisible,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                session : this.session,
                category : this.category,
                name : this.name,
                start : this.start,
                end : this.end,
                noteType : this.noteType,
                period : this.period,
                fileType : this.fileType,
                noteMax : this.noteMax,
                subventionMax : this.subventionMax,
                correctionGrid : this.correctionGrid,
                correction : this.correction,
                fileRef : this.fileRef,
                isVisible : this.isVisible,
                responses : this.responses,
                correctionIsVisible : this.correctionIsVisible,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Challenge