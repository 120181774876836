<template>
    <span v-if="profile.role != 'User' || (userSession && userSession.displayParameters && userSession.displayParameters.challenges)">
        <v-card>
            <v-card-title primary-title class="justify-center">
                <h1 class="text-center mb-3">Challenges</h1>
            </v-card-title>
            <v-card-text v-if="profile.role != 'User'">
                <v-row class="d-flex flex-row align-center justify-center">
                    <v-col cols="12" md="4">
                        <v-select :items="sessions" item-text="name" return-object label="Sélectionnez une session" v-model="selectedSession"></v-select>
                    </v-col>
                </v-row>
                <span v-if="selectedSession">
                    <v-tabs v-model="currentTab" color="primary" centered slider-color="primary">
                        <v-tab>
                            <span>Challenges</span>
                        </v-tab>
                        <v-tab>
                            <span>Correction</span>
                        </v-tab>
                        <v-tab>
                            <span>Bilan des notes</span>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="currentTab">
                        <v-tab-item class="py-3">
                            <ChallengesList :session="selectedSession" :profile="profile"/>
                        </v-tab-item>
                        <v-tab-item class="py-3">
                            <ChallengesCorrection :session="selectedSession" :profile="profile"/>
                        </v-tab-item>
                        <v-tab-item class="py-3">
                            <ChallengesNotes :session="selectedSession" :profile="profile"/>
                        </v-tab-item>
                    </v-tabs-items>
                </span>
            </v-card-text>
            <v-card-text v-else>
                <ChallengesUsers :session="selectedSession" :profile="profile"/>
            </v-card-text>
        </v-card>
    </span>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js"

import ChallengesList from "@/components/challenges/ChallengesList.vue"
import ChallengesUsers from "@/components/challenges/ChallengesUsers.vue"
import ChallengesCorrection from "@/components/challenges/ChallengesCorrection.vue"
import ChallengesNotes from "@/components/challenges/ChallengesNotes.vue"

import Session from '@/classes/Session'
import Team from '@/classes/Team'
import Univers from '@/classes/Univers'

export default {
    name: "Challenges",
    props: ["user", "profile", "userSession"],
    components: {
        ChallengesList,
        ChallengesUsers,
        ChallengesCorrection,
        ChallengesNotes,
    },
    setup() {
        return { 
            dataFormater,
        }
    }, 
    data() {
        return {
            unsub: [],
            sessions: [],
            currentTab: null,
            selectedSession: null,
        }
    },
    async created() {
        if(this.profile.role != "User"){
            this.unsub.push(Session.listenOnlySession((sessions) => {
                this.sessions = sessions
                sessions.sort((a, b) => b.date - a.date)
            }))
        }else{
            let selectedTeam = await Team.getById(this.profile.team)
            let currentUnivers = await Univers.getById(selectedTeam.univers)
            this.selectedSession = await Session.getById(currentUnivers.session)
        }
    },
    methods: {

    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>
