<template>
  <v-card>
    <v-card-text>
      <v-row class="pl-5">
        <v-col cols="12">
          <v-data-table :headers="challengesHeaders" :items="challenges">
            <template v-slot:item.isVisible="{ item }">
              <v-btn
                color="primary"
                icon
                v-if="item.isVisible"
                @click="toggleVisibility(item)"
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
              <v-btn
                color="warning"
                icon
                v-else
                @click="toggleVisibility(item)"
              >
                <v-icon>mdi-eye-off-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.responses="{ item }">
              <span
                v-if="item.fileType.length > 0"
                v-text="
                  Object.keys(item.responses).length + '/' + totalTeamAmount
                "
                :class="
                  Object.keys(item.responses).length == 0
                    ? 'error--text'
                    : Object.keys(item.responses).length == totalTeamAmount
                    ? 'success--text'
                    : ''
                "
              ></span>
              <span v-else class="accent--text">N/A</span>
            </template>
            <template v-slot:item.noteType="{ item }">
              <v-chip
                small
                color="success"
                outlined
                v-if="item.noteType == 'cash'"
              >
                <v-icon left> mdi-cash </v-icon>
                <span>Subvention</span>
              </v-chip>
              <v-chip
                small
                color="primary"
                outlined
                v-else-if="item.noteType == 'note'"
              >
                <v-icon left> mdi-school-outline </v-icon>
                <span>Note</span>
              </v-chip>
              <v-chip small color="accent" outlined v-else>
                <v-icon left> mdi-close </v-icon>
                <span>Aucune</span>
              </v-chip>
            </template>
            <template v-slot:item.period="{ item }">
              <span v-text="periodTable[item.period]"></span>
            </template>
            <template v-slot:item.fileType="{ item }">
              <v-chip
                small
                color="accent"
                outlined
                v-if="item.fileType.length <= 0"
              >
                <v-icon left> mdi-close </v-icon>
                <span>Aucun</span>
              </v-chip>
              <span v-else>
                <v-chip
                  small
                  color="secondary"
                  outlined
                  v-for="(ext, index) in item.fileType"
                  v-if="index <= 2"
                >
                  <span v-text="'.' + ext"></span>
                </v-chip>
                <v-chip
                  small
                  color="secondary"
                  outlined
                  v-if="item.fileType.length > 3"
                >
                  <span
                    v-text="
                      '... et ' +
                      (item.fileType.length - 3) +
                      ' autre' +
                      (item.fileType.length - 3 > 1 ? 's' : '')
                    "
                  ></span>
                </v-chip>
              </span>
            </template>
            <template v-slot:item.fileRef="{ item }">
              <v-btn
                color="secondary"
                disabled
                icon
                v-if="item.fileRef == null"
              >
                <v-icon>mdi-file-question-outline</v-icon>
              </v-btn>
              <v-btn color="success" icon v-else @click="openFile(item)">
                <v-icon>mdi-file-document-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn color="primary" icon @click="openfileUploadDialog(item)">
                <v-icon>mdi-upload</v-icon>
              </v-btn>
              <v-btn
                color="secondary"
                icon
                @click="openEditChallengeDialog(item)"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn color="error" icon @click="deleteChallenge(item)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" class="pa-3 d-flex justify-center">
          <v-btn
            color="accent"
            outlined
            class="mx-2"
            @click="createCategoryDialog = true"
          >
            <v-icon>mdi-plus</v-icon>
            <span>Ajouter une categorie</span>
          </v-btn>
          <v-btn
            color="primary"
            outlined
            class="mx-2"
            @click="createChallengeDialog = true"
          >
            <v-icon>mdi-plus</v-icon>
            <span>Ajouter un challenge</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="createCategoryDialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          primary-title
          class="d-flex flex-rox align-center justify-space-between"
        >
          Ajouter une categorie
          <v-btn
            icon
            color="secondary"
            @click="
              createCategoryDialog = false;
              newCategoryName = '';
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="newCategoryName" label="Nom"></v-text-field>
          <span class="d-flex flex-rox align-center justify-center">
            <v-btn color="primary" outlined @click="addCategory">Valider</v-btn>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileUploadDialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          primary-title
          class="d-flex flex-rox align-center justify-space-between"
        >
          Charger une nouvel énnoncé
          <v-btn icon color="secondary" @click="closeFileUploadDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-file-input
            color="primary"
            accept=".pdf"
            truncate-length="32"
            label="Fichier PDF"
            v-model="fileUploadData.file"
          ></v-file-input>
          <span class="d-flex flex-rox align-center justify-center">
            <v-btn color="primary" outlined @click="addFile">Valider</v-btn>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createChallengeDialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          primary-title
          class="d-flex flex-rox align-center justify-space-between"
        >
          Ajouter un challenge
          <v-btn icon color="secondary" @click="closeCreateChallengeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newChallengeData.name"
            label="Nom"
          ></v-text-field>
          <v-select
            :items="categories"
            v-model="newChallengeData.category"
            item-text="name"
            item-value="name"
            label="Category"
          ></v-select>
          <v-text-field
            type="datetime-local"
            label="Debut du challenge"
            v-model="newChallengeData.start"
          ></v-text-field>
          <v-text-field
            type="datetime-local"
            label="Fin du challenge"
            v-model="newChallengeData.end"
          ></v-text-field>
          <v-select
            :items="noteTypes"
            v-model="newChallengeData.noteType"
            item-text="text"
            item-value="value"
            label="Type de notation"
          ></v-select>
          <v-select
            :items="periods"
            v-model="newChallengeData.period"
            item-text="name"
            item-value="id"
            label="Periode d'effet"
            v-if="newChallengeData.noteType != 'none'"
          ></v-select>
          <v-switch
            label="Ajouter une zone de dépôt"
            v-model="newChallengeData.createFileZone"
          ></v-switch>
          <v-select
            :items="fileTypes"
            v-model="newChallengeData.fileType"
            label="Type de fichier"
            multiple
            v-if="newChallengeData.createFileZone"
            clearable
          ></v-select>
          <v-switch
            label="Ajouter une grille de correction"
            v-model="newChallengeData.createCorrectionGrid"
            v-if="newChallengeData.noteType == 'note'"
          ></v-switch>
          <v-simple-table
            v-if="
              newChallengeData.noteType == 'note' &&
              newChallengeData.createCorrectionGrid
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Critere</th>
                  <th class="text-center">points</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="criteria in newChallengeData.correctionGrid">
                  <td class="pa-1">
                    <v-text-field
                      hide-details=""
                      v-model="criteria.name"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="pa-1">
                    <v-text-field
                      hide-details=""
                      v-model="criteria.points"
                      suffix="Point(s)"
                      type="number"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="pa-1">
                    <v-btn
                      color="error"
                      icon
                      @click="removeNewCriteria(criteria)"
                    >
                      <v-icon color="error">mdi-delete-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span
            class="mt-1 d-flex flex-rox align-center justify-center"
            v-if="
              newChallengeData.noteType == 'note' &&
              newChallengeData.createCorrectionGrid
            "
          >
            <v-btn
              color="primary"
              outlined
              @click="
                newChallengeData.correctionGrid.push({ name: '', points: '' })
              "
              >Ajouter un critere</v-btn
            >
          </span>
          <v-text-field
            type="number"
            label="Note maximale"
            v-model="newChallengeData.noteMax"
            suffix="Point(s)"
            v-if="
              newChallengeData.noteType == 'note' &&
              !newChallengeData.createCorrectionGrid
            "
          ></v-text-field>
          <v-text-field
            type="number"
            label="Subvention maximale"
            v-model="newChallengeData.subventionMax"
            suffix="€"
            v-if="newChallengeData.noteType != 'none'"
          ></v-text-field>

          <span class="d-flex flex-rox align-center justify-center">
            <v-btn color="primary" outlined @click="addChallenge"
              >Valider</v-btn
            >
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editChallengeDialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          primary-title
          class="d-flex flex-rox align-center justify-space-between"
        >
          Modifier un challenge
          <v-btn icon color="secondary" @click="closeEditChallengeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editChallengeData.name"
            label="Nom"
          ></v-text-field>
          <v-select
            :items="categories"
            v-model="editChallengeData.category"
            item-text="name"
            item-value="name"
            label="Category"
          ></v-select>
          <v-text-field
            type="datetime-local"
            label="Debut du challenge"
            v-model="editChallengeData.start"
          ></v-text-field>
          <v-text-field
            type="datetime-local"
            label="Fin du challenge"
            v-model="editChallengeData.end"
          ></v-text-field>
          <v-select
            :items="noteTypes"
            v-model="editChallengeData.noteType"
            item-text="text"
            item-value="value"
            label="Type de notation"
          ></v-select>
          <v-select
            :items="periods"
            v-model="editChallengeData.period"
            item-text="name"
            item-value="id"
            label="Periode d'effet"
            v-if="editChallengeData.noteType != 'none'"
          ></v-select>
          <v-switch
            label="Ajouter une zone de dépôt"
            v-model="editChallengeData.createFileZone"
          ></v-switch>
          <v-select
            :items="fileTypes"
            v-model="editChallengeData.fileType"
            label="Type de fichier"
            multiple
            v-if="editChallengeData.createFileZone"
            clearable
          ></v-select>
          <v-switch
            label="Ajouter une grille de correction"
            v-model="editChallengeData.createCorrectionGrid"
            v-if="editChallengeData.noteType == 'note'"
          ></v-switch>
          <v-simple-table
            v-if="
              editChallengeData.noteType == 'note' &&
              editChallengeData.createCorrectionGrid
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Critere</th>
                  <th class="text-center">points</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="criteria in editChallengeData.correctionGrid">
                  <td class="pa-1">
                    <v-text-field
                      hide-details=""
                      v-model="criteria.name"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="pa-1">
                    <v-text-field
                      hide-details=""
                      v-model="criteria.points"
                      suffix="Point(s)"
                      type="number"
                      outlined
                    ></v-text-field>
                  </td>
                  <td class="pa-1">
                    <v-btn
                      color="error"
                      icon
                      @click="removeEditCriteria(criteria)"
                    >
                      <v-icon color="error">mdi-delete-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span
            class="mt-1 d-flex flex-rox align-center justify-center"
            v-if="
              editChallengeData.noteType == 'note' &&
              editChallengeData.createCorrectionGrid
            "
          >
            <v-btn
              color="primary"
              outlined
              @click="
                editChallengeData.correctionGrid.push({ name: '', points: '' })
              "
              >Ajouter un critere</v-btn
            >
          </span>
          <v-text-field
            type="number"
            label="Note maximale"
            v-model="editChallengeData.noteMax"
            suffix="Point(s)"
            v-if="
              editChallengeData.noteType == 'note' &&
              !editChallengeData.createCorrectionGrid
            "
          ></v-text-field>
          <v-text-field
            type="number"
            label="Subvention maximale"
            v-model="editChallengeData.subventionMax"
            suffix="€"
            v-if="editChallengeData.noteType != 'none'"
          ></v-text-field>

          <span class="d-flex flex-rox align-center justify-center">
            <v-btn color="primary" outlined @click="saveChallenge"
              >Valider</v-btn
            >
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import Swal from "sweetalert2/dist/sweetalert2.js";
import notifier from "@/assets/functions/notifier.js";

import Challenge from "@/classes/Challenge";
import ChallengeCategory from "@/classes/ChallengeCategory";
import Period from "@/classes/Period";
import Team from "@/classes/Team";
import Univers from "@/classes/Univers";

export default {
  name: "ChallengesList",
  props: ["session", "profile"],
  setup() {
    return {
      storage: getStorage(),
    };
  },
  data() {
    return {
      unsub: [],
      challengesHeaders: [
        {
          text: "Visible",
          value: "isVisible",
          sortable: true,
        },
        {
          text: "Nom",
          value: "name",
          sortable: true,
        },
        {
          text: "Catégorie",
          value: "category",
          sortable: true,
        },
        {
          text: "Type de notation",
          value: "noteType",
          sortable: true,
        },
        {
          text: "Periode d'effet",
          value: "period",
          sortable: true,
        },
        {
          text: "Type de fichier",
          value: "fileType",
          sortable: false,
        },
        {
          text: "Enoncé",
          value: "fileRef",
          sortable: false,
        },
        {
          text: "Completion",
          value: "responses",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      challenges: [],
      periods: [],
      createChallengeDialog: false,
      createCategoryDialog: false,
      categories: [],
      noteTypes: [
        {
          value: "none",
          text: "Acune",
        },
        {
          value: "cash",
          text: "Subvention",
        },
        {
          value: "note",
          text: "Note",
        },
      ],
      fileTypes: [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "csv",
        "ppt",
        "pptx",
        "zip",
        "rar",
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "img",
        "txt",
        "mp3",
        "wav",
        "ogg",
        "mp4",
        "avi",
        "mov",
      ],

      newCategoryName: "",
      newChallengeData: {
        category: "Sans catégorie",
        name: "",
        // start: new Date(new Date().getTime() + 2000 * 60 * 60)
        //   .toISOString()
        //   .slice(0, 16),
        start: new Date(
          new Date().getTime() -
            new Date(new Date().getTime()).getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .slice(0, 16),
        // end: new Date(new Date().getTime() + 3000 * 60 * 60)
        //   .toISOString()
        //   .slice(0, 16),
        end: new Date(
          new Date().getTime() +
            3600000 -
            new Date(new Date().getTime()).getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .slice(0, 16),
        noteType: "none",
        noteMax: 20,
        subventionMax: 10000,
        period: null,
        createFileZone: false,
        fileType: [],
        createCorrectionGrid: false,
        correctionGrid: [],
        correction: {},
      },
      editChallengeDialog: false,
      editChallengeData: {},
      fileUploadDialog: false,
      fileUploadData: {
        challenge: null,
        file: null,
      },
      totalTeamAmount: 0,
    };
  },
  created() {
    this.unsub.push(
      ChallengeCategory.listenAll((challengeCategories) => {
        this.categories = challengeCategories;
      })
    );
  },
  watch: {
    session: {
      async handler(val) {
        if (val) {
          this.unsub.push(
            Period.listenBySession(this.session.id, (periods) => {
              let tmpPeriods = periods;
              tmpPeriods.sort((a, b) => a.end - b.end);
              this.periods = tmpPeriods;
            })
          );
          this.unsub.push(
            Challenge.listenBySession(this.session.id, (challenges) => {
              this.challenges = challenges;
            })
          );
          let teamAmount = 0;
          let universes = await Univers.getBySession(this.session.id);
          for (let univers of universes) {
            let teams = await Team.getByUnivers(univers.id);
            teamAmount += teams.length;
          }
          this.totalTeamAmount = teamAmount;
        }
      },
      immediate: true,
    },
  },
  computed: {
    periodTable() {
      let tmpPeriods = {};
      this.periods.forEach((period) => {
        tmpPeriods[period.id] = period.name;
      });
      return tmpPeriods;
    },
  },
  methods: {
    addCategory() {
      if (this.newCategoryName.trim().length > 0) {
        let cat = new ChallengeCategory(null, this.newCategoryName.trim());
        cat.save();
        this.newCategoryName = "";
        this.createCategoryDialog = false;
      }
    },
    openfileUploadDialog(item) {
      this.fileUploadData.challenge = item;
      this.fileUploadData.file = null;
      this.fileUploadDialog = true;
    },
    async addFile() {
      if (
        this.fileUploadData.file &&
        this.fileUploadData.file.constructor.name == "File"
      ) {
        let name = this.fileUploadData.challenge.id + ".pdf";
        let fileRef = ref(this.storage, "challenge/" + name);
        await uploadBytes(fileRef, this.fileUploadData.file);
        this.fileUploadData.challenge.fileRef = name;
        await this.fileUploadData.challenge.save();
      }
      this.closeFileUploadDialog();
    },
    openFile(item) {
      if (item.fileRef) {
        getDownloadURL(ref(this.storage, "challenge/" + item.fileRef)).then(
          (url) => {
            window.open(url, "_blank");
          }
        );
      }
    },
    closeFileUploadDialog() {
      this.fileUploadDialog = false;
      this.fileUploadData = {
        challenge: null,
        file: null,
      };
    },
    closeCreateChallengeDialog() {
      this.createChallengeDialog = false;
      this.newChallengeData = {
        category: "Sans catégorie",
        name: "",
        // start: new Date(new Date().getTime() + 2000 * 60 * 60)
        //   .toISOString()
        //   .slice(0, 16),
        start: new Date(
          new Date().getTime() -
            new Date(new Date().getTime()).getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .slice(0, 16),
        // end: new Date(new Date().getTime() + 3000 * 60 * 60)
        //   .toISOString()
        //   .slice(0, 16),
        end: new Date(
          new Date().getTime() +
            3600000 -
            new Date(new Date().getTime()).getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .slice(0, 16),
        noteType: "none",
        noteMax: 20,
        subventionMax: 10000,
        period: null,
        createFileZone: false,
        fileType: [],
        createCorrectionGrid: false,
        correctionGrid: [],
        correction: {},
      };
    },
    openEditChallengeDialog(challenge) {
      this.editChallengeData = JSON.parse(JSON.stringify(challenge));

      // this.editChallengeData.start = new Date(
      //   this.editChallengeData.start + 60 * 60 * 2000
      // )
      //   .toISOString()
      //   .slice(0, 16);

      this.editChallengeData.start = new Date(
        this.editChallengeData.start -
          new Date(this.editChallengeData.start).getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 16);

      // this.editChallengeData.end = new Date(
      //   this.editChallengeData.end + 60 * 60 * 2000
      // )
      //   .toISOString()
      //   .slice(0, 16);

      this.editChallengeData.end = new Date(
        this.editChallengeData.end -
          new Date(this.editChallengeData.end).getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 16);

      (this.editChallengeData.createFileZone =
        this.editChallengeData.fileType.length > 0),
        (this.editChallengeData.createCorrectionGrid =
          this.editChallengeData.correctionGrid.length > 0),
        (this.editChallengeDialog = true);
    },
    closeEditChallengeDialog() {
      this.editChallengeDialog = false;
      this.editChallengeData = {};
    },
    removeNewCriteria(criteria) {
      this.newChallengeData.correctionGrid.splice(
        this.newChallengeData.correctionGrid.indexOf(criteria),
        1
      );
    },
    removeEditCriteria(criteria) {
      this.editChallengeData.correctionGrid.splice(
        this.editChallengeData.correctionGrid.indexOf(criteria),
        1
      );
    },
    async addChallenge() {
      if (
        this.newChallengeData.noteType == "note" &&
        this.newChallengeData.createCorrectionGrid
      ) {
        this.newChallengeData.noteMax = 0;
        this.newChallengeData.correctionGrid.forEach((critere) => {
          this.newChallengeData.noteMax += parseInt(critere.points);
        });
      }
      if (this.newChallengeData.noteType == "none") {
        this.newChallengeData.noteMax = null;
        this.newChallengeData.subventionMax = null;
        this.newChallengeData.period = null;
        this.newChallengeData.correctionGrid = [];
      }
      if (this.newChallengeData.noteType == "cash") {
        this.newChallengeData.noteMax = null;
        this.newChallengeData.correctionGrid = [];
      }
      if (!this.newChallengeData.createFileZone) {
        this.newChallengeData.fileType = [];
      }
      if (!this.newChallengeData.createCorrectionGrid) {
        this.newChallengeData.correctionGrid = [];
      }

      for (let critere of this.newChallengeData.correctionGrid) {
        if (critere.name.trim().length == 0) {
          this.removeNewCriteria(criteria);
        }
        if (critere.points.trim().length == 0) {
          this.removeNewCriteria(criteria);
        }
      }

      let challenge = new Challenge(
        null,
        this.session.id,
        this.newChallengeData.category,
        this.newChallengeData.name,
        new Date(this.newChallengeData.start).getTime(),
        new Date(this.newChallengeData.end).getTime(),
        this.newChallengeData.noteType,
        this.newChallengeData.period,
        this.newChallengeData.fileType,
        this.newChallengeData.noteMax,
        this.newChallengeData.subventionMax,
        this.newChallengeData.correctionGrid,
        this.newChallengeData.correction,
        null,
        false,
        {},
        false
      );
      let newChallenge = await challenge.save();

      this.closeCreateChallengeDialog();
    },
    saveChallenge() {
      if (
        this.editChallengeData.noteType == "note" &&
        this.editChallengeData.createCorrectionGrid
      ) {
        this.editChallengeData.noteMax = 0;
        this.editChallengeData.correctionGrid.forEach((critere) => {
          this.editChallengeData.noteMax += parseInt(critere.points);
        });
      }
      if (this.editChallengeData.noteType == "none") {
        this.editChallengeData.noteMax = null;
        this.editChallengeData.subventionMax = null;
        this.editChallengeData.period = null;
        this.editChallengeData.correctionGrid = [];
      }
      if (this.editChallengeData.noteType == "cash") {
        this.editChallengeData.noteMax = null;
        this.editChallengeData.correctionGrid = [];
      }
      if (!this.editChallengeData.createFileZone) {
        this.editChallengeData.fileType = [];
      }
      if (!this.editChallengeData.createCorrectionGrid) {
        this.editChallengeData.correctionGrid = [];
      }

      for (let critere of this.editChallengeData.correctionGrid) {
        if (critere.name.trim().length == 0) {
          this.removeEditCriteria(criteria);
        }
        if (critere.points.trim().length == 0) {
          this.removeEditCriteria(criteria);
        }
      }

      let challenge = new Challenge(
        this.editChallengeData.id,
        this.editChallengeData.session,
        this.editChallengeData.category,
        this.editChallengeData.name,
        new Date(this.editChallengeData.start).getTime(),
        new Date(this.editChallengeData.end).getTime(),
        this.editChallengeData.noteType,
        this.editChallengeData.period,
        this.editChallengeData.fileType,
        this.editChallengeData.noteMax,
        this.editChallengeData.subventionMax,
        this.editChallengeData.correctionGrid,
        this.editChallengeData.correction,
        this.editChallengeData.fileRef,
        this.editChallengeData.isVisible,
        this.editChallengeData.responses,
        this.editChallengeData.correctionIsVisible
      );
      challenge.save();
      this.$forceUpdate();
      this.closeEditChallengeDialog();
    },
    deleteChallenge(challenge) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez vous vraiment supprimer ce challenge ?",
        icon: "question",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await notifier.cleanOldNotif(this.session.id, challenge.id);
          challenge.delete();
          this.$forceUpdate();
        }
      });
    },
    toggleVisibility(item) {
      item.isVisible = !item.isVisible;
      item.save();

      if (item.isVisible) {
        notifier.sendToSession(this.session.id, "challenge", {
          id: item.id,
          date: item.start,
        });
      }
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
