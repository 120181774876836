import { parse } from "path"
import * as XLSX from "xlsx/xlsx.mjs"

const dataExtractor = {
    getTableFromId(wraperId, id){
        let wraper = document.querySelector("#"+wraperId)
        let tableWraper = wraper.querySelector("#"+id)
        let table = tableWraper.querySelector("table")
        return table
    },

    exportResult(resultWraperId){
        let bilanHtmlTable = this.getTableFromId(resultWraperId, "bilan")
        let resultAccountHtmlTable = this.getTableFromId(resultWraperId, "resultAccount")
        let treasuryAccount1HtmlTable = this.getTableFromId(resultWraperId, "treasuryAccount1")
        let treasuryAccount2HtmlTable = this.getTableFromId(resultWraperId, "treasuryAccount2")
        let salesTableHtmlTable = this.getTableFromId(resultWraperId, "salesTable")
        let productionTableHtmlTable = this.getTableFromId(resultWraperId, "productionTable")

        let wb = XLSX.utils.book_new()

        let bilanSheet = XLSX.utils.table_to_sheet(bilanHtmlTable)
        bilanSheet = this.formatSheetData(bilanSheet)
        XLSX.utils.book_append_sheet(wb, bilanSheet, "Bilan")

        let resultAccountSheet = XLSX.utils.table_to_sheet(resultAccountHtmlTable)
        resultAccountSheet = this.formatSheetData(resultAccountSheet)
        XLSX.utils.book_append_sheet(wb, resultAccountSheet, "Compte de résultat")

        let treasuryAccountSheet = XLSX.utils.table_to_sheet(treasuryAccount1HtmlTable)
        XLSX.utils.sheet_add_dom(treasuryAccountSheet, treasuryAccount2HtmlTable, {origin: "D1"});
        treasuryAccountSheet = this.formatSheetData(treasuryAccountSheet)
        XLSX.utils.book_append_sheet(wb, treasuryAccountSheet, "Compte de trésorerie")

        let salesTableSheet = XLSX.utils.table_to_sheet(salesTableHtmlTable)
        salesTableSheet = this.formatSheetData(salesTableSheet)
        XLSX.utils.book_append_sheet(wb, salesTableSheet, "Tableau des ventes")

        let productionTableSheet = XLSX.utils.table_to_sheet(productionTableHtmlTable)
        productionTableSheet = this.formatSheetData(productionTableSheet)
        XLSX.utils.book_append_sheet(wb, productionTableSheet, "Tableau de production")

        XLSX.writeFile(wb, "result.xlsx")
    },

    exportTable(researchWraperId, currentReasearchId, name){
        let wb = XLSX.utils.book_new()

        let researchHtmlTable = this.getTableFromId(researchWraperId, currentReasearchId)
        let sheet = XLSX.utils.table_to_sheet(researchHtmlTable)
        sheet = this.formatSheetData(sheet)
        XLSX.utils.book_append_sheet(wb, sheet, name)
        
        XLSX.writeFile(wb, name+".xlsx")
    },

    formatSheetData(sheet){
      for(let key in sheet){
        if(sheet[key].v != undefined && sheet[key].t != undefined && (sheet[key].t == "n" || sheet[key].t == "s")){
          let tmp_value = sheet[key].v.toString()
          if(/^(\d{0,3}\.)*(\d{0,3}\,\d{0,2} €)$/g.test(sheet[key].v)){
            tmp_value = tmp_value.replaceAll(" €", "").replaceAll(".", "").replaceAll(",", ".")
  
            tmp_value = parseFloat(tmp_value)
            sheet[key].v = tmp_value
            sheet[key].t = "n"
          }else if(/^(\d*\.?\d* *%)$/g.test(tmp_value)){
            tmp_value = tmp_value.replaceAll(".", "").replaceAll(",", "").replaceAll("%", "")

            tmp_value = parseFloat(tmp_value)/100
            sheet[key].v = tmp_value
            sheet[key].t = "n"
          }else if(/^(\d*\.?\d*)$/g.test(tmp_value)){
            tmp_value = parseFloat(tmp_value)
            sheet[key].v = tmp_value
            sheet[key].t = "n"
          }else{
            sheet[key].t = "s"
          }
        }
      }
      return sheet
    }
}

export default dataExtractor