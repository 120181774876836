import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "challengeCategories"

class ChallengeCategory{
    constructor(id, name){
        this.id = id
        this.name = name
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let challengeCategories = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_challengeCategory = new ChallengeCategory(doument.id, data.name)
            challengeCategories.push(tmp_challengeCategory)
        })

        return challengeCategories
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let challengeCategories = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_challengeCategory = new ChallengeCategory(document.id, data.name)
                challengeCategories.push(tmp_challengeCategory)
            })
            
            if(callback != null){
                callback(challengeCategories)
            }
        })
        return unsub
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                name : this.name,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                name : this.name,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default ChallengeCategory