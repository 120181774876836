
<template>
    <span>
        <v-row class="pl-5">
            <v-col cols="12">
                <v-tabs v-model="currentTab" color="primary" centered slider-color="primary">
                    <v-tab v-for="challenge in challenges" v-if="challenge.isVisible" @click="cleanNotif(notifs[challenge.id])">
                        <span v-text="challenge.name"></span>
                        <v-badge color="accent" v-if="notifs[challenge.id]"> <span slot="badge" v-text="'!'"></span> </v-badge>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item class="py-3" v-for="challenge in challenges" v-if="challenge.isVisible">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-sheet elevation="3" style="width:100%; min-height: 70vh; height: 100%;" class="d-flex align-center justify-center" v-if="challenge.fileRef">
                                    <iframe :src="challenge.fileUrl" frameborder="0" style="width:100%; height: 100%"></iframe>
                                </v-sheet>
                                <v-sheet elevation="3" style="width:100%; min-height: 70vh;" class="d-flex flex-column align-center justify-center" v-else>
                                    <h2 class="text-center">Ce challenge ne comporte pas d'énoncé</h2>
                                    <v-img contain src="@/assets/images/lost.svg" max-height="300px" max-width="300px" width="100%" height="100%"></v-img>
                                </v-sheet>
                            </v-col>
                            <v-col cols="12" md="6">
                                <h2 class="mt-5">A rendre pour : </h2>
                                <h3 class="mt-3" :class="(new Date(challenge.end).getTime() < new Date().getTime() && (challenge.responses[profile.team] == undefined || challenge.responses[profile.team] == undefined || challenge.responses[profile.team].length <= 0)) ? 'error--text' : ''">
                                    <span v-text="new Date(challenge.end).toLocaleString()"></span>
                                    <span v-if="new Date(challenge.end).getTime() < new Date().getTime() && (challenge.responses[profile.team] == undefined || challenge.responses[profile.team] == undefined || challenge.responses[profile.team].length <= 0)" v-text=" ' ('+ Math.floor((new Date().getTime() - new Date(challenge.end).getTime())/(1000 * 60))+' minute(s) de retard)'"></span>
                                </h3>
                                <div class="my-10">
                                    <v-divider></v-divider>
                                </div>
                                <h2 class="mt-5">Fichiers déposés :</h2>
                                <v-simple-table v-if="challenge.fileType != undefined && challenge.fileType.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Nom du fichier</th>
                                            <th>date de depot</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="challenge.responses[profile.team] != undefined && challenge.responses[profile.team] != undefined">
                                        <tr v-for="file in challenge.responses[profile.team]">
                                            <td v-text="file.originalName"></td>
                                            <td v-text="new Date(file.date).toLocaleString()"></td>
                                            <td>
                                                <v-btn color="primary" icon>
                                                    <v-icon @click="downloadResponse(file)">mdi-download-outline</v-icon>
                                                </v-btn>
                                                <v-btn color="error" icon @click="deleteFile(challenge, file)">
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                                <h3 class="mt-5" v-else>La zone de depot est désactivée !</h3>
                                <span class="mt-3 d-flex align-center justify-center"  v-if="challenge.fileType != undefined && challenge.fileType.length > 0">
                                    <v-btn color="primary" outlined @click="openfileUploadDialog(challenge)">Deposer un fichier</v-btn>
                                </span>
                                <div class="my-10">
                                    <v-divider></v-divider>
                                </div>
                                <h2 class="mt-5">Subvention obtenue :</h2>
                                <h3 class="mt-3" v-if="!challenge.isVisible || !challenge.correctionIsVisible">Aucune subvention obtenue pour le moment</h3>
                                <h3 class="mt-3" v-else>
                                    <span>Vous avez obtenue&nbsp;</span>
                                    <span class="primary--text" v-text="dataFormater.formatMoney(parseFloat(challenge.correction[profile.team].subvention))"></span>
                                    <span>/</span>
                                    <span v-text="dataFormater.formatMoney(parseFloat(challenge.subventionMax))"></span>
                                    <span>&nbsp;pour ce challenge</span>
                                    <br>
                                    <span>(cette subvention s'appliquera en </span>
                                    <span class="primary--text" v-text="periodTable[challenge.period]"></span>
                                    <span>)</span>
                                </h3>
                                <div class="my-10">
                                    <v-divider></v-divider>
                                </div>
                                <h2 class="mt-5">Commentaire du correcteur :</h2>
                                <h3 class="mt-3" v-if="!challenge.isVisible || !challenge.correctionIsVisible || challenge.correction[profile.team].comment.trim() == ''">Aucun commentaire disponible pour le moment</h3>
                                <h3 class="mt-3" v-else v-text="challenge.correction[profile.team].comment.trim()"></h3>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-dialog v-model="fileUploadDialog" persistent max-width="600px">
            <v-card>
                <v-card-title primary-title class="d-flex flex-rox align-center justify-space-between">
                    Charger un fichier
                    <v-btn icon color="secondary" @click="closeFileUploadDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text v-if="fileUploadData.challenge != undefined && fileUploadData.challenge.fileType != undefined">
                    <v-file-input color="primary" :accept="getAcceptedTypes(fileUploadData.challenge)" truncate-length="32" :label="'Fichier (' + getAcceptedTypes(fileUploadData.challenge)+')'" v-model="fileUploadData.file"></v-file-input>
                    <span class="d-flex flex-rox align-center justify-center">
                        <v-btn color="primary" outlined @click="addFile">Valider</v-btn>
                    </span>
                </v-card-text>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"

import Swal from 'sweetalert2/dist/sweetalert2.js'

import dataFormater from "@/assets/functions/dataFormater.js"

import Challenge from '@/classes/Challenge'
import ChallengeCategory from '@/classes/ChallengeCategory'
import Period from '@/classes/Period'
import Notif from '@/classes/Notif'

export default {
    name: "ChallengesUsers",
    props : ["session", "profile"],
    setup() {
        return{
            storage : getStorage(),
            dataFormater,
        }
    },
    data() {
        return {
            unsub: [],
            challenges: [],
            periods: [],
            currentTab : null,
            fileUploadDialog : false,
            fileUploadData : {
                challenge : null,
                file : null,
            },
            notifs : {},
        }
    },
    created() {
        if(this.profile && this.profile.role == "User" && this.profile.team != null){
            this.unsub.push(Notif.listenByProfile(this.profile.id, (notifs) => {
                let tmp_notifs = {}

                for(let notif of notifs){
                    if(notif.type == "challenge"){
                        tmp_notifs[notif.data.id] = notif
                    }
                }
                this.notifs = tmp_notifs
            }))
        }
    },
    watch: {
        session : {
            handler(val){
                if(val){
                    this.unsub.push(Period.listenBySession(this.session.id, (periods) => {
                        let tmpPeriods = periods
                        tmpPeriods.sort((a, b) => a.end - b.end)
                        this.periods = tmpPeriods
                    }))
                    this.unsub.push(Challenge.listenBySession(this.session.id, async (challenges) => {
                        this.challenges = challenges.filter(challenge => new Date().getTime() > new Date(challenge.start).getTime())
                        for(let challenge of this.challenges){
                            if(challenge.fileRef){
                                challenge.fileUrl = await getDownloadURL(ref(this.storage, 'challenge/' + challenge.fileRef))
                            }else{
                                challenge.fileUrl = null
                            }
                        }
                        this.$forceUpdate()
                    }))
                }
            },
            immediate : true
        }
    },
    computed: {
        periodTable(){
            let tmpPeriods = {}
            this.periods.forEach(period => {
                tmpPeriods[period.id] = period.name
            })
            return tmpPeriods
        }
    },
    methods: {
        openfileUploadDialog(item){
            this.fileUploadData.challenge = item
            this.fileUploadData.file = null
            this.fileUploadDialog = true
        },
        async addFile(){
            if(this.fileUploadData.file && this.fileUploadData.file.constructor.name == 'File'){
                if(this.fileUploadData.challenge.fileType.includes(this.fileUploadData.file.name.split('.').pop())){
                    let name = this.fileUploadData.challenge.id+'_'+this.profile.team+'_'+new Date().getTime() + '.pdf'
                    let fileRef = ref(this.storage, 'challengeResponse/' + name)
                    await uploadBytes(fileRef, this.fileUploadData.file)
                    if(this.fileUploadData.challenge.responses == undefined){
                        this.fileUploadData.challenge.responses = {}
                    }
                    if(this.fileUploadData.challenge.responses[this.profile.team] == undefined){
                        this.fileUploadData.challenge.responses[this.profile.team] = []
                    }
                    this.fileUploadData.challenge.responses[this.profile.team].push({
                        originalName : this.fileUploadData.file.name,
                        fileRef : name,
                        date : new Date().getTime(),
                    })
                    await this.fileUploadData.challenge.save()
                }else{
                    Swal.fire({
                        title: 'Erreur',
                        text: "Le type de ce fichier n'est pas accepté",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }
            this.closeFileUploadDialog()        
        },
        getAcceptedTypes(challenge){
            let types = []
            if(challenge.fileType != undefined){
                for(let type of challenge.fileType){
                    types.push('.'+type)
                }
            }
            return types.join(', ')
        },
        async getFileUrl(challenge){
            if(challenge.fileRef){
                return await getDownloadURL(ref(this.storage, 'challenge/' + challenge.fileRef))
            }
            return null
        },
        async downloadResponse(file){
            if(file.fileRef){
                window.open(await getDownloadURL(ref(this.storage, 'challengeResponse/' + file.fileRef)), '_blank')
            }
        },
        deleteFile(challenge,file){
            Swal.fire({
                title: 'Êtes-vous sûr ?',
                text: "Voulez vous vraiment supprimer ce fichier ?",
                icon: 'question',
                showCancelButton: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if(challenge.responses[this.profile.team].length == 1){
                        delete challenge.responses[this.profile.team]
                    }else{
                        challenge.responses[this.profile.team].splice(challenge.responses[this.profile.team].indexOf(file), 1)
                    }
                    await challenge.save()
                    this.$forceUpdate()
                }
            })
        },
        closeFileUploadDialog(){
            this.fileUploadDialog = false
            this.fileUploadData = {
                challenge : null,
                file : null,
            }
        },
        cleanNotif(notif){
            notif.delete()
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if (typeof unsub == "function") {
                unsub()
            }
        })
    },
}
</script>